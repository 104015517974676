import React from 'react';
import ContentMarketing from '../assets/images/svg icon/content marketing.svg';
import conversionRate from '../assets/images/svg icon/conversion rates.svg';
import ecommerce from '../assets/images/svg icon/e-commerce website.svg';
import email from '../assets/images/svg icon/e-mail.svg';
import googleAds from '../assets/images/svg icon/google ads.svg';
import PPC from '../assets/images/svg icon/pay per click marketing.svg';
import SEO from '../assets/images/svg icon/search engine optimization.svg';
import SMM from '../assets/images/svg icon/social media marketing.svg';
import webDevelopment from '../assets/images/svg icon/web development.svg';

const Services = () => {
    return (
        <section className="container mx-auto p-8">
        <div className="mx-auto text-center max-w-screen-sm">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">
          Your Brand. Our Services.
          </h2>
          <p className="mb-4 text-gray-500 sm:text-xl">
          Scale with Confidence. We've Got You Covered. 
          </p>
        </div>
        <div className="p-4 lg:p-16  col-span-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 justify-items-center">
            {/* card-1 */}
            <div className="bg-white rounded-lg shadow-xl p-6 relative border border-purple-400">
                {/* Sphere */}
                <div
                    className="bg-black text-white p-3 rounded-full absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <img src={ecommerce} alt="" className="w-6 h-6 animate-pulse"/>


                </div>

                {/* Content */}
                <div className="mt-8 flex flex-col">
                    <h2 className="text-xl text-center font-semibold mb-2">Shopify Store</h2>
                    <h3 className="text-gray-600 text-center mb-4">Building online stores with features like product
                        listings, shopping carts, secure payment gateways, and inventory management.</h3>
                    
                </div>
            </div>
            {/* card-7 */}
            <div className="bg-white rounded-lg shadow-xl p-6 relative border border-black">
                {/* Sphere */}
                <div
                    className="bg-purple-600 text-white p-3 rounded-full absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <img src={email} alt="" className="w-6 h-6 animate-pulse"/>


                </div>

                {/* Content */}
                <div className="mt-8 flex flex-col">
                    <h2 className="text-xl text-center font-semibold mb-2">Klaviyo Email marketing</h2>
                    <h3 className="text-gray-600 text-center mb-4">We help businesses build relationships with their
                        customers and promote their products and services.</h3>
                    
                </div>
            </div>
            {/* card-2  */}
            <div className="bg-white rounded-lg shadow-xl p-6 relative border border-black">
                {/* Sphere */}
                <div
                    className="bg-purple-600 text-white p-3 rounded-full absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <img src={webDevelopment} alt="" className="w-6 h-6 animate-pulse"/>


                </div>

                {/* Content */}
                <div className="mt-8 flex flex-col">
                    <h2 className="text-xl text-center font-semibold mb-2">Web Design & Development</h2>
                    <h3 className="text-gray-600 text-center mb-4">We create professional and user-friendly websites that
                        will help businesses attract and engage customers.</h3>

                    {/* <Link to="
                    https://api.whatsapp.com/send/?phone=8801951815814&text&type=phone_number&app_absent=0"
                        target="_blank" data-tooltip-target="tooltip-bottom-1" data-tooltip-placement="bottom"
                        className="text-purple-700 bg-primary-600 border border-1 border-purple-700 hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-3 py-1 text-center mb-8">Learn
                        More</Link>
                    <div id="tooltip-bottom-1" role="tooltip"
                        className="text-center absolute z-10 invisible inline-block px-3 py-2 text-sm font-normal text-white bg-purple-600 rounded-lg shadow-sm opacity-0 tooltip">
                        Click Here To Learn More About About Web Design & Development
                        <div className="tooltip-arrow" data-popper-arrow></div>
                    </div> */}


                </div>
            </div>
            
            {/* card-3 */}
            <div className="bg-white rounded-lg shadow-xl p-6 relative border border-purple-400">
                {/* Sphere */}
                <div
                    className="bg-purple-600 text-white p-3 rounded-full absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <img src={SEO} alt="" className="w-6 h-6 animate-pulse"/>


                </div>

                {/* Content */}
                <div className="mt-8 flex flex-col">
                    <h2 className="text-xl text-center font-semibold mb-2">Search Engine Optimization</h2>
                    <h3 className="text-gray-600 text-center mb-4">We help businesses improve their website's search engine
                        ranking so that they can be found by more potential customers.</h3>
                    
                </div>
            </div>
            {/* card-4 */}
            <div className="bg-white rounded-lg shadow-xl p-6 relative border border-black">
                {/* Sphere */}
                <div
                    className="bg-black text-white p-3 rounded-full absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <img src={SMM} alt="" className="w-6 h-6 animate-pulse"/>


                </div>

                {/* Content */}
                <div className="mt-8 flex flex-col">
                    <h2 className="text-xl text-center font-semibold mb-2">Social media marketing</h2>
                    <h3 className="text-gray-600 text-center mb-4">We help businesses grow their audience and engagement on
                        social media platforms.</h3>
                    
                </div>
            </div>
            {/* card-5 */}
            <div className="bg-white rounded-lg shadow-xl p-6 relative border border-purple-400">
                {/* Sphere */}
                <div
                    className="bg-purple-600 text-white p-3 rounded-full absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2">

                    <img src={ContentMarketing} alt="" className="w-6 h-6 animate-pulse"/>


                </div>

                {/* Content */}
                <div className="mt-8 flex flex-col">
                    <h2 className="text-xl text-center font-semibold mb-2">Content marketing</h2>
                    <h3 className="text-gray-600 text-center mb-4">We create high-quality content that will attract and
                        engage your target audience.</h3>
                    
                </div>
            </div>
            {/* card-6 */}
            <div className="bg-white rounded-lg shadow-xl p-6 relative border border-purple-400">
                {/* Sphere */}
                <div
                    className="bg-black text-white p-3 rounded-full absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <img src={PPC} alt="" className="w-6 h-6 animate-pulse"/>


                </div>

                {/* Content */}
                <div className="mt-8 flex flex-col">
                    <h2 className="text-xl text-center font-semibold mb-2">PPC advertising</h2>
                    <h3 className="text-gray-600 text-center mb-4">We help businesses get their website seen by more people
                        with pay-per-click advertising.</h3>
                    
                </div>
            </div>
            {/* card-8 */}
            <div className="bg-white rounded-lg shadow-xl p-6 relative border border-purple-400">
                {/* Sphere */}
                <div
                    className="bg-black text-white p-3 rounded-full absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <img src={conversionRate} alt="" className="w-6 h-6 animate-pulse"/>

                </div>

                {/* Content */}
                <div className="mt-8 flex flex-col">
                    <h2 className="text-xl text-center font-semibold mb-2">Conversion rate optimization</h2>
                    <h3 className="text-gray-600 text-center mb-4">We help businesses increase their website's conversion
                        rate so that they can get more leads and sales.</h3>
                    
                </div>
            </div>
            {/* card-9 */}
            <div className="bg-white rounded-lg shadow-xl p-6 relative border border-black">
                {/* Sphere */}
                <div
                    className="bg-purple-600 text-white p-3 rounded-full absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <img src={googleAds} alt="" className="w-6 h-6 animate-pulse"/>


                </div>

                {/* Content */}
                <div className="mt-8 flex flex-col">
                    <h2 className="text-xl text-center font-semibold mb-2">Google Ads marketing services</h2>
                    <h3 className="text-gray-600 text-center mb-4">We help businesses get their website seen by more people
                        with Google Ads.</h3>

                </div>
            </div>

        </div>
    </section>
    );
};

export default Services;