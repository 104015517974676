import React from 'react';
import { Link } from 'react-router-dom';

const Hero = () => {
    return (
        <section className="container mx-auto mt-20 lg:mt-16">
      <div className="px-4 mx-auto max-w-screen-xl text-center  py-12 lg:py-16 lg:px-12">
        {/* <h2 className="text-4xl font-extrabold tracking-tight leading-none md:text-5xl lg:text-7xl bg-clip-text text-transparent bg-gradient-to-r from-purple-900 to-purple-400 lg:pb-4">
        A flexible suite of white-label services tailored to scale with your clients' needs and budgets.
        </h2> */}
        <h2
                class="text-4xl font-extrabold tracking-tight leading-none  md:text-5xl lg:text-7xl bg-clip-text text-transparent bg-gradient-to-r from-purple-900 to-purple-400 lg:pb-4 mt-8">
                Your White-Label Shopify & Email Marketing Powerhouse</h2>
        <h1 className="my-8 text-lg font-normal text-gray-500 lg:text-2xl sm:px-16 xl:px-48">
        Seamless execution. Invisible partnership. Tangible results.
        </h1>
        <div className="flex flex-col mb-4 lg:mb-8 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
          <Link
            to="https://api.whatsapp.com/send/?phone=8801951815814&text&type=phone_number&app_absent=0"
            target="_blank"
            className="inline-flex justify-center items-center py-3 px-12 text-base font-medium text-center bg-black text-gray-100 rounded-lg hover:bg-gray-700 border border-black hover:border-gray-700 focus:ring-4 focus:ring-purple-800" rel="noreferrer"
          >
            
            <svg className="mr-2 -ml-1 w-5 h-5" fill="currentColor" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"/></svg>
            Let’s Talk
          </Link>
          <Link
            to="/consultation"
            className="inline-flex justify-center items-center py-3 px-12 text-base font-medium text-center text-gray-900 rounded-lg border border-purple-600 hover:border-gradient-to-r from-purple-900 to-purple-400 hover:bg-gradient-to-r from-purple-900 to-purple-400 hover:text-white focus:ring-4 focus:ring-gray-100" rel="noreferrer"
          >
            <svg className="mr-2 -ml-1 w-5 h-5" fill="currentColor" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 448 512"><path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192H400V448c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192z"/></svg>
            Schedule A Meeting
          </Link>
        </div>
      </div>
    </section>
    );
};

export default Hero;